.navWrapper {
  position: relative;
  top: 0%;
  z-index: 999;
  background: var(--neutral-100);

  &.fixed {
    position: sticky;
    top: 0%;
    animation: navDown .5s forwards;
  }

  @keyframes navDown {
    0% {
      transform: translateY(-100%)
    }

    100% {
      transform: translateY(0)
    }
  }

  .toggled {
    display: none;
  }

  .nav {
    padding: 10px 0px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "logo links button";
    grid-column-gap: clamp(16px, calc(20vw/7.68), 60px);
    position: relative;
    grid-template-rows: auto;

    .hamburger {
      display: none;
    }

    .logo {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }

    .button {
      align-self: flex-start;
      justify-self: flex-end;
      grid-area: button;
      padding: 10px clamp(16px, calc(20vw/7.68), 96px);
    }

    .linksWrapper {
      display: grid;
      grid-area: links;

      .links {
        display: grid;
        column-gap: clamp(16px, calc(30vw/7.68), 60px);
        justify-self: flex-end;
        align-items: start;
        grid-template-columns: auto 1fr auto;
        font-size: 24px;
        padding-top: 8px;
        position: absolute;

        >a {
          color: var(--midnight-green-500);
        }

        .dropdown {

          display: grid;
          grid-template-columns: 1fr;
          grid-template-areas: "button" "link";
          position: relative;

          svg {
            transition: transform 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96);
          }

          .expandedLinks {
            display: grid;
            grid-template-columns: 1fr;
            position: absolute;
            top: 48px;
            width: 240px;
            padding: 24px;
            background: var(--neutral-100);
            border-radius: 16px;
            box-shadow: 0px 1px 2px 0px rgba(64, 64, 64, 0.10),
              2px 3px 4px 0px rgba(64, 64, 64, 0.09);
            background: var(--neutral-100);
            gap: 24px;

            >a {
              width: fit-content;
              font-size: 20px;
              color: var(--midnight-green-500);

              &:hover {
                text-decoration: underline;
              }

              &:focus-visible {
                outline-offset: 5px;
                border-radius: 4px;
                outline: 1px solid var(--midnight-green-500);
              }
            }

            &[data-isexpanded="false"] {
              display: none;
            }
          }

          .button {
            padding: unset;
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            align-self: center;
            justify-self: flex-end;
            gap: 10px;
            color: var(--midnight-green-500);
          }

          &.expanded {
            gap: 24px;

            svg {
              transform: rotateX(180deg);
            }
          }

          >a {
            color: var(--midnight-green-500);
          }
        }
      }
    }
  }

  @media (max-width: 1149px) {
    .nav {
      grid-template-areas: "logo hamburger";
      grid-template-columns: auto 1fr;

      .linksWrapper {
        display: none;
      }

      .button {
        display: none;
      }

      .hamburger {
        display: block;
        justify-self: flex-end;
      }
    }

    .toggled {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--neutral-100);
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas: "logo exit" "links ." "socials ." "button button";
      box-shadow: 0px 1px 2px 0px rgba(64, 64, 64, 0.10),
        2px 3px 4px 0px rgba(64, 64, 64, 0.09);
      border-radius: 0px 0px 12px 0px;
      padding: 9px var(--pageMargin) 16px;
      max-width: 600px;
      width: 100%;
      row-gap: 48px;
      align-items: center;
      column-gap: clamp(16px, calc(30vw/7.68), 60px);

      &[data-show="false"] {
        display: none;
      }

      .logo {
        width: 328.7px;
        height: 100%;
        object-fit: contain;
        grid-area: logo;
        display: block;
      }

      @media (max-width: 458px) {
        grid-template-columns: 1fr auto;
        padding-top: 10px;

        .logo {
          align-self: center;
          width: 100%;

        }
      }

      .exit {
        grid-area: exit;
      }

      .closeNavigationButton {
        display: grid;
        justify-self: flex-end;
        width: fit-content;
        grid-area: exit;
        height: 62px;
        width: 62px;
        justify-content: center;
        align-items: center;
      }

      .links {
        display: grid;
        gap: 24px;
        width: fit-content;
        font-size: 24px;
        grid-area: links;

        .sublinks {
          margin-top: -8px;
          display: grid;
          width: fit-content;
          gap: 16px;
          font-size: 16px;
        }

        a,
        span {
          color: var(--midnight-green-500);
          width: fit-content;
        }
      }

      .button {
        grid-area: button;
      }

      .socials {
        grid-area: socials;
        display: flex;
        gap: 24px;

        >a {
          display: block;

          >img {
            width: 39px;
            height: 39px;
            display: block;
          }

          &:focus-visible {
            outline-offset: 1px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}