*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --midnight-green-100: #359297;
  --midnight-green-200: #2D7C80;
  --midnight-green-300: #25666A;
  --midnight-green-400: #1D5053;
  --midnight-green-500: #163C3E;
  --midnight-green-600: #143638;
  --midnight-green-700: #123234;
  --midnight-green-800: #112E2F;
  --midnight-green-900: #0F2A2B;

  --coffee-100: #B47659;
  --coffee-200: #A96A4C;
  --coffee-300: #995F45;
  --coffee-400: #88553D;
  --coffee-500: #784B36;
  --coffee-600: #6F4532;
  --coffee-700: #67402E;
  --coffee-800: #5E3B2A;
  --coffee-900: #553526;

  --fire-brick-100: #D66464;
  --fire-brick-200: #D25454;
  --fire-brick-300: #CD4545;
  --fire-brick-400: #C93535;
  --fire-brick-500: #BA3131;
  --fire-brick-600: #AC2E2E;
  --fire-brick-700: #9F2A2A;
  --fire-brick-800: #922727;
  --fire-brick-900: #842323;

  --dark-neutral-100: #6C6C6C;
  --dark-neutral-200: #545454;
  --dark-neutral-300: #3B3B3B;
  --dark-neutral-400: #232323;
  --dark-neutral-500: #0A0A0A;
  --dark-neutral-600: #090909;
  --dark-neutral-700: #080808;
  --dark-neutral-800: #070707;
  --dark-neutral-900: #060606;

  --neutral-100: #FFF;
  --neutral-200: #FAFAFA;
  --neutral-300: #F6F6F6;
  --neutral-400: #F5F5F5;
  --neutral-500: #F4F4F4;
  --neutral-600: #DCDCDC;
  --neutral-700: #C3C3C3;
  --neutral-800: #B7B7B7;
  --neutral-900: #ABABAB;

  --green-100: #96EDCD;
  --green-200: #6BE5B9;
  --green-300: #56E1AF;
  --green-400: #41DEA5;
  --green-500: #2CDA9B;
  --green-600: #27BF8C;
  --green-700: #21A67E;
  --green-800: #1F996D;
  --green-900: #1A835D;

  --background: #EFEFEF;

  --light-background: #f9f9f9;

  --border: #E0E2E1;

  --gradient: linear-gradient(268deg, #134E51, #5E8B8D);

  --error-500: #BA3131;
  --error-800: #922727;

  --quoteBorder: #B9B9B9;

  --background-color: #AFD8DA;
}

:focus-visible {
  outline: 2px solid var(--midnight-green-500);
  outline-offset: 7px;
  border-radius: 16px;
}

body {
  min-width: 320px;
  background-color: var(--neutral-100);
  color: var(--dark-neutral-300);
  font-size: 16px;
  font-family: 'Kanit', sans-serif;
  display: grid;
  overflow-x: hidden;
  --pageMargin: clamp(24px, calc(24vw/7.68), 100px);
}

html {
  scroll-padding-top: 100px;
}

main,
.maxWidth {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1280px;
  width: calc(100% - var(--pageMargin)*2);
  margin: 0 auto;
}


.fullWidthBackground {
  position: relative;

  &::before {
    content: '';
    width: 100dvw;
    height: 100%;
    background-color: inherit;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
  }
}

main {
  padding-top: 75px;
  row-gap: 75px;

  @media (max-width: 1399px) {
    padding-top: clamp(60px, calc(80vw/7.68), 100px);
  }
}

svg {
  vertical-align: top;
}

a {
  text-decoration: none;
  color: var(--dark-neutral-900);
}

button {
  border: none;
  cursor: pointer;
}

sup {
  font-size: .6em;
  vertical-align: top;
}

input,
textarea,
button,
select {
  font: inherit;
  color: inherit;
  appearance: none;
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-neutral-900);
  line-height: 1.16;
  font-weight: 400;
  font-size: inherit;

  strong,
  &.strong {
    font-weight: 400;
    color: var(--midnight-green-500);
  }
}

h1 {
  font-size: clamp(calc(32rem/16), calc(32vw/7.68), calc(48rem/16));
}

h2 {
  font-size: clamp(calc(32rem/16), calc(32vw/7.68), calc(48rem/16));
}

h3 {
  font-size: clamp(calc(24rem/16), calc(32vw/7.68), calc(32rem/16));

}

h4 {
  font-size: clamp(calc(16rem/16), calc(24vw/7.68), calc(24rem/16));
}

p {
  font-size: clamp(calc(16rem/16), calc(20vw/7.68), calc(24rem/16));
  font-weight: 300;
}

p strong,
p.strong,
li strong {
  color: var(--dark-neutral-900);
  font-weight: 400;
}


.unorderedList {
  list-style: none;
  display: grid;
  gap: 20px;

  >li {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 19px;
    align-items: center;

    span {
      font-size: clamp(calc(16rem/16), calc(24vw/7.68), calc(24rem/16));
      font-weight: 300;
    }
  }
}

li a {
  color: var(--coffee-500);
  text-decoration: underline;
  font-size: clamp(calc(16rem/16), calc(24vw/7.68), calc(24rem/16));
}